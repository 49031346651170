<template>
  <v-col>
    <Error :alarmdata="alarmdata" />
  </v-col>
</template>
<script>
import Error from '@/views/components/Notification/ErrorGroup.vue';

export default {
  name: 'GeneralStatsHour',
  components: {
    Error,
  },
  data: () => ({
    alarmdata: {},
  }),
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
